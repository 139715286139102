export default function LakindusMessage() {   

    return (
        <div className="text-center container px-3" id='LakinduMessage'>

            <div className='row mt-4 mb-4 justify-content-center align-items-center bg-dark shadow' id="LakinduMessage-Row">
                
                {/* Text section */}
                <div className='col-12 col-md-7 col-lg-7 p-3 p-md-4 text-start'>
                    <div className="text text-white p-3 p-md-4">
                        <p className='text-white fs-5 fs-md-5'>
                            At Akwid Labs, we’re a team of software engineers, data scientists, and creative minds passionate about empowering businesses with AI-powered solutions.
                            Our mission is to build intelligent tools that help businesses thrive in the digital age by boosting productivity, reducing costs, and enabling data-driven decision-making.
                        </p>
                        <p className='text-white fs-5 fs-md-5 pt-0'>
                            We offer services in <b>Product Engineering, Research & Development, Consulting, and Team Augmentation</b> to partners worldwide including the USA, Australia, New Zealand, and Sri Lanka. 
                            Whether you're a startup, an enterprise, or a solo entrepreneur, we're here to help you build the next big thing and grow your business to the next level in the digital era.
                            Let's connect and discuss how we can help you achieve your business goals with AI-powered solutions.
                        </p>
                        <p className='text-white fs-5 fs-md-5 pt-0 mb-0'>
                            Lakindu Widuranga Alwis <br/>
                            <span className='fs-6 fs-md-6'>
                                <i>
                                CEO & Co-Founder, Akwid Labs (Pvt) Ltd
                                </i>
                            </span> <br/>
                            <span className='fs-7 fs-md-6'>
                                <i>
                                 <a href="https://calendly.com/lakindu/15min" target="_blank" rel="noreferrer" className='text-white text-decoration-underline' >
                                   Book a Call &gt;
                                 </a>
                                </i>
                            </span>


                        </p>
                    </div>
                </div>

                {/* Image section */}
                <div className='col-12 col-md-5 col-lg-5 text-center p-3'>
                    <img src='/Images/Lakindu.JPG' className='img-fluid border border-light'
                    alt="Lakindu Widuranga Alwis, CEO & Co-Founder of Akwid Labs (Pvt) Ltd. A young entrepreneur, software engineer, and AI enthusiast passionate about empowering businesses with AI-powered solutions." />
                </div>

            </div>
        </div>
    );
}
