
const Footer = () => {
    const year = new Date().getFullYear();
    const footerlogo = require('../Images/Brand-Logo/Akwid-Labs.png');

    return (
        <div className="footer bg-dark text-center text-white" id="footer">
            <div className="container pt-4 pb-4">
                <div className="row p-3">
                    <div className="col-lg-6 col-md-12 mb-4 mb-md-0 text-start">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 mb-4 mb-md-0">
                                <img
                                    alt="Logo of the Akwid Labs"
                                    src={footerlogo}
                                    width="200"
                                    className="d-inline-block align-top"
                                />
                            </div>
                        </div>
                        <p className="mt-3">
                            Akwid Labs is your one-stop solution for all your software development needs. We specialize in Software Engineering and Consulting, Cloud Technologies, Artificial Intelligence and Web Technologies.
                        </p>
                        <hr className="m-1" />
                        <div className="social-icons mt-3">
                            <a href="https://www.facebook.com/AkwidLabs/" className="fa fa-facebook" aria-label="Facebook"></a>
                            <a href="https://x.com/AkwidLabs" className="fa fa-twitter" aria-label="Twitter"></a>
                            <a href="https://www.linkedin.com/company/akwidlabs" className="fa fa-linkedin" aria-label="LinkedIn"></a>
                            <a href="https://www.instagram.com/akwid.labs/" className="fa fa-instagram" aria-label="Instagram"></a>
                            {/* <a href="#!" className="fa fa-youtube" aria-label="YouTube"></a> */}
                            <a href="https://github.com/Akwid-Labs" className="fa fa-github" aria-label="GitHub"></a>
                            <a href="/blog" className="fa fa-medium" aria-label="Medium"></a>
                            <a href="#!" aria-label="AkwidLabs">#AkwidLabs</a>
                            <a href="#!" aria-label="Akwid">#Akwid</a>
                        </div>
                    </div>

                    <div className="col-lg-6">

                                <div className="row">

                                        <div className="col-lg-6 col-md-4 mb-4 mb-md-0 text-start footer-links">
                                            <h5 className="text-uppercase"> Company </h5>
                                            <ul className="list-unstyled mb-0">
                                                <li><a href="/products" className="text-white p-1 pt-2">Products</a></li>
                                                <li><a href="/services" className="text-white p-1 pt-2">Solutions</a></li>
                                                <li><a href="/casestudies" className="text-white p-1 pt-2">Case Studies</a></li>
                                                <li><a href="/team" className="text-white p-1 pt-2">Team</a></li>
                                                <li><a href="/Blog" className="text-white p-1 pt-2">Blog</a></li>
                        
                                            </ul>
                                        </div>

                                        <div className="col-lg-6 col-md-6 mb-4 mb-md-0 text-start footer-links">
                                            <h5 className="text-uppercase"> Important Links </h5>
                                            <ul className="list-unstyled mb-0">
                                                <li><a href="/partnership" className="text-white p-1 pt-2">Partnership</a></li>
                                                <li><a href="/privacypolicy" className="text-white p-1 pt-2">Privacy Policy</a></li>
                                                <li><a href="/termsandconditions" className="text-white p-1 pt-2">Terms and Conditions</a></li>
                                                <li><a href="/careers" className="text-white p-1 pt-2">Careers</a></li>
                                                <li><a href="/contacts" className="text-white p-1 pt-2">Contacts</a></li>
                                                
                                            </ul>
                                        </div>


                                </div>
                    </div>

                </div>
                <hr className="m-1" />
                <div className="text-center p-3">
                    © {year} All rights reserved | Akwid Labs (Pvt) Ltd
                </div>
            </div>
        </div>
    );
}

export default Footer;