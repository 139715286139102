import '../../Styles/TermsAndConditions.css';
const TermsAndConditions = () => {
  return (
    <div id="terms-and-conditions" className="container mt-5">
      <h1 id="terms-title" className="text-center">Terms and Conditions</h1>
      <p id="effective-date" className="text-center"><strong>Effective Date:</strong> July 10, 2024 </p>

      <section id="introduction" className="my-4">
        <h2 id="introduction-title">Introduction</h2>
        <p id="introduction-content">
          Welcome to Akwid Labs (Pvt) LTD! These terms and conditions outline the rules and regulations for the use of Akwid Labs's Website, located at akwidlabs.com.
        </p>
      </section>

      <section id="agreement" className="my-4">
        <h2 id="agreement-title">Agreement to Terms</h2>
        <p id="agreement-content">
          By accessing this website we assume you accept these terms and conditions. Do not continue to use Akwid Labs (Pvt) LTD if you do not agree to take all of the terms and conditions stated on this page.
        </p>
      </section>

      <section id="cookies" className="my-4">
        <h2 id="cookies-title">Cookies</h2>
        <p id="cookies-content">
          We employ the use of cookies. By accessing Akwid Labs (Pvt) LTD, you agreed to use cookies in agreement with the Akwid Labs (Pvt) LTD's Privacy Policy.
        </p>
      </section>

      <section id="license" className="my-4">
        <h2 id="license-title">License</h2>
        <p id="license-content">
          Unless otherwise stated, Akwid Labs (Pvt) LTD and/or its licensors own the intellectual property rights for all material on Akwid Labs (Pvt) LTD. All intellectual property rights are reserved. You may access this from Akwid Labs (Pvt) LTD for your own personal use subjected to restrictions set in these terms and conditions.
        </p>
        <ul id="license-restrictions">
          <li id="restriction-1">Republish material from Akwid Labs (Pvt) LTD</li>
          <li id="restriction-2">Sell, rent or sub-license material from Akwid Labs (Pvt) LTD</li>
          <li id="restriction-3">Reproduce, duplicate or copy material from Akwid Labs (Pvt) LTD</li>
          <li id="restriction-4">Redistribute content from Akwid Labs (Pvt) LTD</li>
        </ul>
      </section>

      <section id="user-comments" className="my-4">
        <h2 id="user-comments-title">User Comments</h2>
        <p id="user-comments-content">
          This Agreement shall begin on the date hereof. Certain parts of this website offer the opportunity for users to post and exchange opinions and information in certain areas of the website. Akwid Labs (Pvt) LTD does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Akwid Labs,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions.
        </p>
      </section>

      <section id="hyperlinking" className="my-4">
        <h2 id="hyperlinking-title">Hyperlinking to our Content</h2>
        <p id="hyperlinking-content">
          The following organizations may link to our Website without prior written approval:
        </p>
        <ul id="approved-organizations">
          <li id="organization-1">Government agencies;</li>
          <li id="organization-2">Search engines;</li>
          <li id="organization-3">News organizations;</li>
          <li id="organization-4">Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
          <li id="organization-5">System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
          <li id="organization-6">These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</li>
        </ul>
      </section>

      <section id="iframes" className="my-4">
        <h2 id="iframes-title">iFrames</h2>
        <p id="iframes-content">
          Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.
        </p>
      </section>

      <section id="content-liability" className="my-4">
        <h2 id="content-liability-title">Content Liability</h2>
        <p id="content-liability-content">
          We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
        </p>
      </section>

      <section id="your-privacy" className="my-4">
        <h2 id="your-privacy-title">Your Privacy</h2>
        <p id="your-privacy-content">
          Please read Privacy Policy.
        </p>
      </section>

      <section id="reservation-rights" className="my-4">
        <h2 id="reservation-rights-title">Reservation of Rights</h2>
        <p id="reservation-rights-content">
          We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
        </p>
      </section>

      <section id="removal-links" className="my-4">
        <h2 id="removal-links-title">Removal of links from our website</h2>
        <p id="removal-links-content">
          If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
        </p>
      </section>

      <section id="disclaimer" className="my-4">
        <h2 id="disclaimer-title">Disclaimer</h2>
        <p id="disclaimer-content">
          To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
        </p>
        <ul id="disclaimer-points">
          <li id="disclaimer-point-1">limit or exclude our or your liability for death or personal injury;</li>
          <li id="disclaimer-point-2">limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
          <li id="disclaimer-point-3">limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
          <li id="disclaimer-point-4">exclude any of our or your liabilities that may not be excluded under applicable law.</li>
        </ul>
        <p id="disclaimer-conclusion">
          The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
        </p>
        <p id="disclaimer-note">
          As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
