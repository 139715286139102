import '../Styles/Service.css';
import ServiceHeader from "../Components/Engineering/ServiceHeader";
import TechStackLogo from '../commen/TechStackLogo';
import ServiceCatogories from '../Components/Engineering/ServiceCatogories';
import ProductEngineering from '../Components/Engineering/ProductEngineering';
import TruestedBy from '../commen/TruestedBy';
import ProgressFlow from '../commen/ProgressFlow';
import CallShedule from '../commen/CallShedule';
import ReactGA from 'react-ga4';
import LakindusMessage from '../Components/ExtraComponents/LakindusMessage';


const Services = () => {

    // set page header 
    document.title = "Akwid Labs | Product Engineering and AI Services";
    document.textContent = "Akwid Labs is a product engineering and AI services company that helps businesses to build AI-driven products and solutions. We provide end-to-end product engineering services to help businesses to build AI-driven products and solutions.";
    
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

    return (
        <div>
            <ServiceHeader />

            <ProductEngineering />

            <LakindusMessage />

            <div className="mt-4 mb-4">
                <TruestedBy />
            </ div>
            
            <div className="p-1 mt-5 mb-5">
                <ProgressFlow />
            </div>

            <div className="mt-5 mb-5">
                <TechStackLogo />
            </div>

            <div className='mt-5 mb-5'>
                <ServiceCatogories />
            </div>

            <CallShedule title="Ready to get started?" />
                 
        </div>
    );
    }

export default Services;